import _find from "lodash/find";
import _flatMap from "lodash/flatMap";
import _flatten from "lodash/flatten";
import _fromPairs from "lodash/fromPairs";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";

import { transcodeOssUrl } from "../oss";
import { targetSort } from "../utils/sort";

import { MatchGroupType } from "./MatchGroup";
import MatchTeam from "./MatchTeam";
import { DEFAULT_AVATAR, UserId } from "./User";

export class RankingRow {
  rawData: Record<string, any>;

  knockoutRank: number;

  matchId: string;

  groupId: string;

  userId: UserId;

  userFullName: string;

  avatarUrl?: string;

  totalScore: number;

  firstRoundScore: number;

  secondRoundScore: number;

  thirdRoundScore: number;

  fourthRoundScore: number;

  xs: number;

  tens: number;

  tenXCount: number;

  nines: number;

  qualifyingTargetNumber: string | null;

  qualifyingTargetLocation: string | null;

  groupType: MatchGroupType;

  registrationId: string | null;

  sex: number;

  waiveFlag: boolean;

  teamName: string;

  teamNameOrClubName: string;

  currentRound: number;

  currentGroup: number;

  constructor(rawData: Record<string, any>) {
    this.rawData = rawData;
    this.knockoutRank = rawData.knockoutRank;
    this.matchId = String(rawData.gameId);
    this.groupId = String(rawData.gameGroupId);
    this.userId = String(rawData.userId);
    this.userFullName = rawData.name;
    this.avatarUrl = rawData.avatorOssUrl ? transcodeOssUrl(rawData.avatorOssUrl) : DEFAULT_AVATAR;
    this.totalScore = rawData.qualifyingRings ?? 0;
    this.firstRoundScore = rawData.firstRoundRings ?? 0;
    this.secondRoundScore = rawData.secondRoundRings ?? 0;
    this.thirdRoundScore = rawData.thirdRoundRings ?? 0;
    this.fourthRoundScore = rawData.forthRoundRings ?? 0;
    this.xs = rawData.xCount ?? 0;
    this.tens = (rawData.tenXCount ?? 0) - (rawData.xCount ?? 0);
    this.tenXCount = rawData.tenXCount ?? 0;
    this.nines = rawData.nineCount ?? 0;
    this.qualifyingTargetNumber = rawData.qualifyingTargetNo;
    this.qualifyingTargetLocation = rawData.qualifyingTargetLocation;
    this.groupType = rawData.groupType as MatchGroupType;
    this.registrationId = rawData.signId;
    this.sex = rawData.sex;
    this.waiveFlag = Boolean(rawData.waiveFlag);
    this.teamName = rawData.teamName;
    this.teamNameOrClubName = rawData.teamName || rawData.clubFullName;
    this.currentRound = rawData.currentRound;
    this.currentGroup = rawData.currentGroup;
  }

  get qualifyingPosition(): string {
    const { qualifyingTargetNo, qualifyingTargetLocation } = this.rawData || {};
    return ![ null, undefined ].includes(qualifyingTargetNo) ? `${qualifyingTargetNo}${qualifyingTargetLocation}` : "";
  }
}

export const sortRankingList = (list: Array<RankingRow> | null): Array<RankingRow> | null => {
  if (list == null) return null;
  if (list.some((row) => row.totalScore)) {
    return list;
  }
  const groupByNo = _groupBy(list, (item) => item.rawData?.qualifyingTargetNo);
  const groupByNoSort = Object.keys(groupByNo).map((num) =>
    groupByNo[ num ].sort((a, b) =>
      targetSort(a.rawData?.qualifyingTargetLocation, b.rawData?.qualifyingTargetLocation)));
  return _flatten(groupByNoSort);
};

export const sortRankingListByScore = (list: Array<{row: RankingRow}>): Array<{ row: RankingRow }> => {
  if (!list.length) return [];
  return list.sort((a, b) => {
    if (a.row.totalScore > b.row.totalScore) {
      return -1;
    }
    if (a.row.totalScore < b.row.totalScore) {
      return 1;
    }
    return 0;
  });
};

export const mergeRankingList = (
  mainList: Array<RankingRow>,
  sourceLists: Array<Array<RankingRow> | undefined>,
  teams: Array<MatchTeam>,
  flatten = true,
): Array<{
  row: RankingRow;
  index: number | null;
  children?: Array<{
    row: RankingRow;
    index: number | null;
  }>;
}> => {
  const sortedMainList = sortRankingList(mainList);
  const sourceRegistrationIdToRankingRowMap = _fromPairs(
    _map(
      _flatten(sourceLists.filter(Boolean)),
      (row) => [ row.registrationId, row ],
    ),
  );
  const mainListWithMembers = _map(sortedMainList, (row, index) => {
    const finalIndex = row?.rawData?.qualifyingRank ?? index + 1;
    if (row.groupType === MatchGroupType.SINGLE) return { row, index: finalIndex };
    const teamId = row.userId;
    const team = _find(teams, (it) => it.teamId === teamId);
    const memberRows = team?.members
      ?.map((member) => sourceRegistrationIdToRankingRowMap[ member.sourceRegistrationId ])
      ?.filter((it) => it != null)
      ?? [];
    const sortedMemberRows = sortRankingList(memberRows) ?? [];
    return {
      row,
      index: finalIndex,
      children: sortRankingListByScore(sortedMemberRows.map(
        (memberRow) => ({ row: memberRow }),
      )),
    };
  });
  if (flatten) {
    return _flatMap(mainListWithMembers, (it) => [
      it, ...(it.children ?? []),
    ]);
  }
  return mainListWithMembers;
};

export default RankingRow;
