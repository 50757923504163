/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import _findLast from "lodash/findLast";
import _flatMap from "lodash/flatMap";
import _isEmpty from "lodash/isEmpty";
import _isNumber from "lodash/isNumber";
import _map from "lodash/map";

import { DailyEventRule } from "./admin";
import DailyEventRegistryItem from "./model/DailyEventRegistryItem";
import Game from "./model/Game";
import GameGroup from "./model/GameGroup";
import { GameGroupStatus } from "./model/GameGroupStatus";
import GameRule from "./model/GameRule";
import HitHighest from "./model/HitHighest";
import Match, { MatchParams, mapMatchParamsToServerParams } from "./model/Match";
import { MatchAccessControlRule } from "./model/MatchAccessControlRule";
import { MatchApplicant } from "./model/MatchApplicant";
import MatchDocument, { MatchDocumentType } from "./model/MatchDocument";
import MatchGroup, { KnockoutRule, MatchGroupParams, mapGroupParamsToServerParams } from "./model/MatchGroup";
import MatchKnockoutMatchUp from "./model/MatchKnockoutMatchUp";
import MatchKnockoutMatchUp2 from "./model/MatchKnockoutMatchUp2";
import MatchKnockoutMatchUpTargetNode from "./model/MatchKnockoutMatchUpTargetNode";
import MatchRegistry, {
  MatchRegistryParams,
  mapMatchRegistryParamsToServerParams,
  MatchRegistryCheckParams,
  mapMatchRegistryCheckParamsToServerParams,
} from "./model/MatchRegistry";
import MatchScorer from "./model/MatchScorer";
import MatchTeam, { mapTeamParamsToServerParams } from "./model/MatchTeam";
import { MixedMatchGroup } from "./model/MixedMatchGroup";
import Phase from "./model/Phase";
import RankingRow from "./model/RankingRow";
import Staff from "./model/Staff";
import Target, { TargetType } from "./model/Target";
import Team from "./model/Team";
import * as ruleHelpers from "./ruleHelper";
import { roundIdToKnockoutId } from "./utils/id";
import {
  afterMapHumanReadableNthFinalWithRule, getHumanReadableNthFinal,
  preMapNthFinalAndSeqToNthFinal,
  getPossibleNthFinalsWithRule,
  mapMaximumNthFinalWithRule,
} from "./utils/knockout";
import { serializeUriComponent } from "./utils/serializeUriComponent";
import { targetSort } from "./utils/sort";

import type { ClubId } from "./model/Club";
import type { MatchTeamParams } from "./model/MatchTeam";

dayjs.extend(customParseFormat);

export enum ExportStatus {
  FAIL = -1, // 导出失败
  UN_EXPORTED = 0, // 未导出
  PENDING = 1, // 导出进行中
  SUCCESS = 2, // 导出成功
}

export const getDailyEventRule = async (
  instance: any,
  { clubId, ruleName, date }: {clubId: ClubId; ruleName: string; date?: string},
): Promise<DailyEventRule> => {
  const gameTypeColumn = ruleHelpers.rule.columns.find((column) => column.backendKey === "gameType");
  const gameType = gameTypeColumn?.backendMapping?.[ruleName];
  const result = await instance.post("/cus/gameGroup/clubHitGroupList", { clubId, gameType, date }, true);
  const rules = _isEmpty(result.data.data) ? [] : result.data.data;
  return new DailyEventRule(rules);
};

export const getDailyEventNumberOfParticipants = async (
  instance: any,
  { clubId, date }: {clubId: ClubId; date?: string},
): Promise<number | null> => {
  const localDate = date ?? dayjs().format("YYYY-MM-DD");
  const result = await instance.post("/cus/gameSign/queryHitNum", { clubId, date: localDate }, true);
  const numberOfParticipants = _isNumber(result.data.data) ? Number(result.data.data) : null;
  return numberOfParticipants;
};

export const getDailyHitHighestList = async (
  instance: any,
  { clubId, date }: {clubId: ClubId; date?: string},
): Promise<any> => {
  const localDate = date ?? dayjs().format("YYYY-MM-DD");
  const result = await instance.post("/cus/gameSign/hitHighestList", { clubId, date: localDate }, true);
  const list = result.data.data || [];
  const hitHighestList = list.map((element) => new HitHighest(element));
  return hitHighestList;
};

export const clubHitTypeGroupList = async (
  instance: any,
  payload: {clubId: ClubId; date?: string; gameType: number; rankingType: number},
): Promise<any> => {
  const localDate = payload.date ?? dayjs().format("YYYY-MM-DD");
  const result = await instance.post("/cus/hit/clubHitTypeGroupList", { ...payload, date: localDate }, true);
  const rules = result.data.data || [];
  return new GameRule(rules, payload.gameType);
};

export const typeRankingList = async (
  instance: any,
  payload: { gameGroupIds: Array<number> },
): Promise<any> => {
  const result = await instance.post("/cus/hit/typeRankingList", { ...payload }, true);
  const list = result.data.data || [];
  return list.map((element) => new RankingRow(element));
};

export const signUp = async (
  instance: any,
  payload,
): Promise<void> => {
  await instance.post(`/cus/gameSign/sign`, payload, true);
};

export const getUserParticipatedMatchGroups = async (
  instance: any,
  { includeFinishedMatches, matchId = undefined, matchTypeList = [ 1, 2, 3 ] }:
  { includeFinishedMatches: boolean; matchId?: number | undefined ; matchTypeList?: number[] }
  = { includeFinishedMatches: false },
): Promise<Array<MatchGroup>> => {
  const statusList = includeFinishedMatches ? [ 0, 1, 2 ] : [ 0, 1 ];
  const result = await instance.post(
    `/cus/gameSign/userGroups`,
    { statusList, gameId: matchId, gameTypeList: matchTypeList },
    true,
  );
  const data = result.data.data;
  if (_isEmpty(data)) return [];
  return data.map((row) => new MatchGroup(row));
};

export const getUserParticipatedMixedMatchGroups = async (
  instance: any,
  data: {
    registerUserId?: number;
    gameId?: number;
    gameGroupId?: number;
  },
): Promise<Array<MixedMatchGroup>> => {
  const result = await instance.post(
    `/cus/mixedGroupRegister/registeredRecords`,
    { ...data },
    true,
  );
  const matchGroupList = result.data?.data?.mixedGroupRegisterList;
  if (_isEmpty(matchGroupList)) return [];
  return matchGroupList.map((row) => new MixedMatchGroup(row));
};

export const submitPhaseScores = async (
  instance: any,
  data: {
    scores: Array<string>;
    groupId: string;
    roundId: string;
    phaseId: string;
    userId: string;
  },
): Promise<void> => {
  const backendData = {
    originRings: data.scores.join(","),
    gameGroupId: data.groupId,
    // backend roundNum starts from 1
    roundNum: Number(data.roundId) + 1,
    // backend groupNum starts from 1
    groupNum: Number(data.phaseId) + 1,
    userId: data.userId,
  };
  await instance.post(`/cus/qualifyingScore/submitGroup`, backendData, true);
};

export const getUserPhases = async (
  instance: any,
  data: {
    groupId: string;
    userId: string;
  },
): Promise<Array<Phase>> => {
  const result = await instance.post(`/cus/qualifyingScore/getUserScore`, {
    gameGroupId: data.groupId,
    userId: data.userId,
  }, true);
  const list = result.data.data;
  if (_isEmpty(list)) return [];
  return list.map((row) => new Phase(row));
};

export const getGroupLeaderboard = async (
  instance: any,
  data: {
    groupId: string;
  },
): Promise<Array<RankingRow>> => {
  const result = await instance.get(`/cus/gameSign/rankingList/${data.groupId}`, null, true);
  const list = result.data.data;
  if (_isEmpty(list)) return [];
  return list.map((row) => new RankingRow(row));
};

export const getRankingList = async (
  instance: any,
  data: {
    groupId: string;
  },
): Promise<Array<RankingRow>> => {
  const result = await instance.get(`/cus/gameSign/allRankingList/${data.groupId}`, null, true);
  const list = result.data.data;
  if (_isEmpty(list)) return [];
  return list.map((row) => new RankingRow(row));
};

export const getSignList = async (
  instance: any,
  data: {
    groupId: string;
  },
): Promise<Array<RankingRow>> => {
  const result = await instance.post(`/cus/gameSign/signList/${data.groupId}`, null, true);
  const list = result.data.data;
  if (_isEmpty(list)) return [];
  return list.map((row) => new RankingRow(row));
};

export const getMatchGroup = async (
  instance: any,
  data: {
    groupId: string;
  },
): Promise<MatchGroup> => {
  const result = await instance.get(`/cus/gameGroup/getById/${data.groupId}`, null, true);
  return new MatchGroup(result.data.data);
};

export const gameStatistics = async (
  instance: any,
  data: { userId: number },
): Promise<{ gameCount: number; highestQualifyingRings: number }> => {
  const result = await instance.get(`/cus/gameSign/gameStatistics`, { userId: data.userId }, true);
  const list = result.data.data;
  return list;
};

export const getDailyEventRegistry = async (
  instance: any,
  data: {
    clubId: number;
    gameTypeList?: number[];
    groupId?: number;
    hasConfirmed?: boolean;
    page: number;
    limit?: number;
  },
): Promise<{ current: number; total: number; list: Array<DailyEventRegistryItem> }> => {
  const result = await instance.post(`/cus/gameSign/signPage`, {
    clubId: data.clubId,
    gameTypeList: data.gameTypeList,
    gameGroupId: data.groupId,
    confirmFlag: data.hasConfirmed ? 1 : 0,
    pageNo: data.page,
    pageLimit: data.limit ?? 10,
  }, true);
  const { current, total, list } = result.data.data;
  if (_isEmpty(list)) return { current, total, list: [] };
  const ret = {
    current,
    total,
    list: list.map((row) => new DailyEventRegistryItem(row)),
  };
  return ret;
};

export const acceptDailyEventResult = async (
  instance: any,
  data: { registryIds: Array<string>},
): Promise<any> => {
  const result = await instance.post(`/bus/game/confirmScore`, { signIdList: data.registryIds }, true);
  return result;
};

export const deleteDailyEventResultAndRegistration = async (
  instance: any,
  data: { registryIds: Array<string>},
): Promise<any> => {
  const result = await instance.post(`/bus/game/deleteSign`, { signIdList: data.registryIds }, true);
  return result;
};

export const modifyPhaseScores = async (
  instance: any,
  data: { phaseId: string; scores: Array<string>},
): Promise<void> => {
  await instance.post(`/bus/game/fixRings`, {
    scoreId: data.phaseId,
    rings: data.scores.join(","),
  }, true);
};

export const getEventRule = async (
  instance: any,
  { clubId, gameType }: {clubId: ClubId; gameType: number},
): Promise<DailyEventRule> => {
  const result = await instance.get(`/bus/rule/list`, { clubId, gameType }, true);
  const rules = _isEmpty(result.data.data) ? [] : result.data.data;
  return new DailyEventRule(rules);
};

// Deprecated
export const getGameGroups = async (
  instance: any,
  gameId: number,
): Promise<GameGroup[]> => {
  const result = await instance.get(`/cus/game/signGameGroupList/${gameId}`, null, true);
  const rawGroups = _isEmpty(result.data.data) ? [] : result.data.data;
  return rawGroups.map((rawGroup) => new GameGroup(rawGroup));
};

export const getGroups = async (
  instance: any,
  { matchId }: { matchId: string },
): Promise<Array<MatchGroup>> => {
  const result = await instance.get(`/cus/game/signGameGroupList/${matchId}`, null, true);
  const rawGroups = _isEmpty(result.data.data) ? [] : result.data.data;
  return rawGroups.map((rawGroup) => new MatchGroup(rawGroup, matchId));
};

export const getFinalizedGroups = async (
  instance: any,
  { matchId }: { matchId: string },
): Promise<Array<MatchGroup>> => {
  const result = await instance.get(`/cus/game/dividedGameGroupList/${matchId}`, null, true);
  const rawGroups = _isEmpty(result.data.data) ? [] : result.data.data;
  return rawGroups.map((rawGroup) => new MatchGroup(rawGroup, matchId));
};

export const getMainPageGameGroups = async (
  instance: any,
  { matchId }: { matchId: string },
): Promise<Array<MatchGroup>> => {
  const result = await instance.get(`/cus/game/mainPageGameGroupList/${matchId}`, null, true);
  const rawGroups = _isEmpty(result.data.data) ? [] : result.data.data;
  return rawGroups.map((rawGroup) => new MatchGroup(rawGroup, matchId));
};

/** @deprecated Use [getMatch] instead */
export const getGameInfo = async (
  instance: any,
  gameId: string,
): Promise<Game | null> => {
  const result = await instance.get(`/cus/game/info/${gameId}`, null, true);
  return _isEmpty(result.data.data) ? null : new Game(result.data.data);
};

export const getMatch = async (
  instance: any,
  payload: { matchId: string },
): Promise<Match | null> => {
  const result = await instance.get(`/cus/game/info/${payload.matchId}`, null, true);
  return _isEmpty(result.data.data) ? null : new Match(result.data.data);
};

export interface KnockoutTreeNode {
  matchUp?: MatchKnockoutMatchUp2;
  targetNode?: MatchKnockoutMatchUpTargetNode;
  nthFinal: number;
  nthFinalHumanReadable: number;
  seq: number;
  left?: KnockoutTreeNode;
  right?: KnockoutTreeNode;
}

export interface KnockoutTournamentData {
  root: KnockoutTreeNode;
  bronzeMedalMatchRoot: KnockoutTreeNode;
  maxNthFinal: number;
  matchUps: Array<MatchKnockoutMatchUp2>;
  matchGroup: MatchGroup;
}

export const transpileKnockoutTournamentData = (matchGroup: MatchGroup, data: any): KnockoutTournamentData => {
  const matchUps = _flatMap(
    data.counterpartRoundList,
    (summary) => summary.knockoutInfoDetail.map((matchUp) => (
      new MatchKnockoutMatchUp2(matchUp, matchGroup.rule.matchKnockoutMatchUpCountTotalRings)
    )),
  );
  const targetNodes = _flatMap(
    data.targetRoundList,
    (summary) => summary.knockoutTargetNodeDetailList.map((matchUp) => (
      new MatchKnockoutMatchUpTargetNode(matchUp)
    )),
  );

  const rule = matchGroup.knockoutMode ?? KnockoutRule.STANDARD;

  const maxNthFinal = mapMaximumNthFinalWithRule(Math.max(
    ...matchUps.map((it) => it.nthFinal),
    ...(_isEmpty(matchUps) ? targetNodes.map((it) => it.nthFinal) : []),
  ), rule);

  const buildTreeNode = (nthFinal: number, seq: number): any => {
    const possibleNthFinals = getPossibleNthFinalsWithRule(nthFinal, rule);
    if (nthFinal > maxNthFinal) {
      return undefined;
    }
    const matchUp = matchUps.find((it) => possibleNthFinals.includes(it.nthFinal) && it.seq === seq);
    const targetNode = targetNodes.find((it) => possibleNthFinals.includes(it.nthFinal) && it.seq === seq);
    return {
      matchUp,
      targetNode,
      nthFinal,
      // Remap nthFinal to human readable string for certain rules
      nthFinalHumanReadable: afterMapHumanReadableNthFinalWithRule(
        getHumanReadableNthFinal(preMapNthFinalAndSeqToNthFinal(nthFinal, seq), true),
        rule,
      ),
      seq,
    };
  };

  const constructTree = (nthFinal: number, seq: number): any => {
    const node = buildTreeNode(nthFinal, seq);
    if (node == null) return undefined;
    return {
      ...node,
      left: constructTree(nthFinal * 2, seq * 2 - 1),
      right: constructTree(nthFinal * 2, seq * 2),
    };
  };

  const root = constructTree(1, 1);

  const hasBronzeMedalMatch = matchUps.some((matchUp) => matchUp.nthFinal === 1 && matchUp.seq === 2)
   || matchUps.some((matchUp) => matchUp.nthFinal > 1);

  const bronzeMedalMatchRoot = hasBronzeMedalMatch ? buildTreeNode(1, 2) : null;

  return {
    root, bronzeMedalMatchRoot, maxNthFinal, matchUps, matchGroup,
  };
};

export const getKnockoutTournament = async (
  instance: any,
  payload: { groupId: string },
): Promise<{ root; bronzeMedalMatchRoot }> => {
  const [ result, result2 ] = await Promise.all([
    instance.post(`/cus/knockOut/queryCounterpartNew`, {
      gameGroupId: payload.groupId,
      needScore: 1,
    }, true),
    instance.post(`/cus/knockoutTarget/queryTarget/${payload.groupId}`, {}, true),
  ]);
  return { ...result.data.data, ...result2.data.data };
};

export const getKnockoutTournamentScore = async (
  instance: any,
  payload: { groupId: string },
): Promise<any> => {
  const result = await instance.post(`/cus/knockOut/queryCounterpart/${payload.groupId}`, {}, true);
  return result.data.data;
};

export const getKnockoutTournamentScoreNew = async (
  instance: any,
  payload: { groupId: string },
): Promise<any> => {
  const result = await instance.post(`/cus/knockOut/queryCounterpartNew`, {
    gameGroupId: payload.groupId,
    needScore: 1,
  }, true);
  return result.data.data;
};

export const getKnockoutTournamentTarget = async (
  instance: any,
  payload: { groupId: string },
): Promise<any> => {
  const result = await instance.post(`/cus/knockoutTarget/queryTarget/${payload.groupId}`, {}, true);
  return result.data.data;
};

export const getApplicants = async (
  instance: any,
  { matchId }: { matchId: string },
): Promise<Array<MatchApplicant>> => {
  const result = await instance.post(`/cus/gameSign/gameSignList/${matchId}`, {}, true);
  const data = result.data.data;
  if (_isEmpty(data)) return [];
  return data.map((applicantRawData) => new MatchApplicant(applicantRawData));
};

export const getApplicants2 = async (
  instance: any,
  { matchId }: { matchId: string },
): Promise<Array<MatchRegistry>> => {
  const result = await instance.post(`/cus/gameSign/gameSignList/${matchId}`, {}, true);
  const data = result.data.data;
  if (_isEmpty(data)) return [];
  return data.map((applicantRawData) => new MatchRegistry(applicantRawData));
};

export const updateMatchRegistry = async (
  instance: any,
  payload: { signId: string; targetNo: number | null; targetLocation: string | null },
): Promise<void> => {
  await instance.post(
    `/bus/game/updateQualifyingTarget`,
    payload,
    true,
  );
};
export const updateSingleKnockoutSite = async (
  instance: any,
  payload: { itemId: string; targetNo: number | null; targetLocation: string | null },
): Promise<void> => {
  await instance.post(
    `/bus/targetSetting/updateSingleKnockoutSite`,
    payload,
    true,
  );
};

export const queryUserCurrentKnockout = async (
  instance: any,
  payload: {
    gameGroupId: number;
    userId?: number;
  },
): Promise<string | null> => {
  const result = await instance.post(`/cus/knockOut/queryUserCurrentKnockout`, payload, true);
  return result.data?.data?.knockoutId || null;
};

export const queryKnockoutDetail = async (
  instance: any,
  payload: { knockoutId: number | string; userId: string },
): Promise<MatchKnockoutMatchUp> => {
  const result = await instance.post(`/cus/knockOut/queryKnockoutDetail/${payload.knockoutId}`, {}, true);
  const data = result.data.data;
  const matchUp = new MatchKnockoutMatchUp(data, payload.userId);
  return matchUp;
};

export const queryKnockoutDetail2 = async (
  instance: any,
  payload: { roundId: string; matchKnockoutMatchUpCountTotalRings: boolean },
): Promise<MatchKnockoutMatchUp2 | null> => {
  const knockoutId = roundIdToKnockoutId(payload.roundId);
  const result = await instance.post(`/cus/knockOut/queryKnockoutDetail/${knockoutId}`, {}, true);
  const data = result.data.data;
  if (_isEmpty(data)) return null;
  const matchUp = new MatchKnockoutMatchUp2(data, payload.matchKnockoutMatchUpCountTotalRings);
  return matchUp;
};

export const submitScore = async (
  instance: any,
  payload: {
    originRings: string;
    knockoutId: number;
    roundNum?: number;
    winFlag?: number; // 胜负 0-负 1-平 2-胜
    extraFlag: number; // 是否附加赛 0-否 1-是
  },
): Promise<any> => {
  const result = await instance.post(`/cus/knockOut/submitScore`, payload, true);
  return result;
};

export const commitBye = async (
  instance: any,
  payload: {
    knockoutId: number;
    userId?: number;
  },
): Promise<string | null> => {
  const result = await instance.post(`/cus/knockOut/commitBye`, payload, true);
  return result.data?.data || null;
};

export const getHitTop = async (
  instance: any,
  payload: {
    clubId: ClubId;
    rankingType: number;
    gameType: number;
    limit: undefined | number;
  },
): Promise<Array<RankingRow>> => {
  const result = await instance.post(`/cus/hit/hitTop`, payload, true);
  const list = result.data.data;
  if (_isEmpty(list)) return [];
  return list.map((row) => {
    const bowColumn = ruleHelpers.rule.columns.find((column) => column.backendKey === "bow") as any;
    const bowName = bowColumn?.reversedBackendMapping?.[ row.clubHitTypeGroup.bow ];
    const gameTypeColumn = ruleHelpers.columnMap[ "打榜模板" ];
    return {
      name: Object.keys(gameTypeColumn.backendMapping)
        .filter((key) => gameTypeColumn.backendMapping[ key ] === payload.gameType)[ 0 ],
      bow: row.clubHitTypeGroup.bow,
      bowName,
      groupName: row.clubHitTypeGroup.groupName.replace(bowName, ""),
      list: row?.rankingList.map((el) => new RankingRow(el)),
    };
  });
};

export const getTeamList = async (
  instance: any,
  clubId: ClubId,
): Promise<Team[]> => {
  const result = await instance.post(`/cus/team/list`, { clubId }, true);
  const teamList = _isEmpty(result.data.data) ? [] : result.data.data;
  return teamList.map((element) => new Team(element));
};

export const createTeam = async (
  instance: any,
  payload: {
    clubId: ClubId;
    teamName: string;
  },
): Promise<void> => {
  await instance.post(`/cus/team/add`, payload, true);
};

export const generateQrcodeUrl = (clubId: ClubId): string => `https://www.archeryclub.cn/mini/event-info?id=${clubId}`;

export const parseQrcodeUrl = (url: string): ClubId | null => {
  const regex = /https:\/\/www\.archeryclub\.cn\/mini\/event-info\?id=(\d*)/gmi;
  const results = regex.exec(url);
  const id = results?.[1];
  if (!id) return null;
  return id;
};

export const generateQrcodeUrlForRankingScoring = (payload: {
  matchId: string;
  groupId: string;
  roundId: string;
  userId: string;
} | {
  matchId: string;
  groupId: string;
  roundId: string;
  position: string;
}): string => `https://www.archeryclub.cn/mini/match-score-ranking?${serializeUriComponent(payload)}`;

export const generateQrcodeUrlForKnockoutScoring = (payload: {
  matchId: string;
  groupId: string;
  nthFinal: number;
  userId: string;
} | {
  matchId: string;
  groupId: string;
  nthFinal: number;
  seq: number;
} | {
  matchId: string;
  groupId: string;
  nthFinal: number;
  position: string;
}): string => `https://www.archeryclub.cn/mini/match-score-knockout?${serializeUriComponent(payload)}`;

// Deprecated. use getMatchRegistry instead
export const getSignInfo = async (
  instance: any,
  payload: { gameGroupId: number | string; userId?: number },
): Promise<Team> => {
  const result = await instance.post("/cus/gameSign/signInfo", payload, true);
  const data = result.data.data;
  return new Team({
    ...data,
    id: data?.teamId,
    name: data?.teamName,
    logo: data?.teamLogo,
  });
};

export const getMatchRegistry = async (
  instance: any,
  payload: { groupId: string; userId: string },
): Promise<MatchRegistry | null> => {
  const result = await instance.post(
    "/cus/gameSign/signInfo",
    {
      gameGroupId: payload.groupId,
      userId: payload.userId,
    },
    true,
  );
  const data = result.data.data;
  if (!data) return null;
  return new MatchRegistry(data);
};

export const knockOutWaive = async (
  instance: any,
  payload: { knockoutId: number | string; userId: number },
): Promise<{ code: string; message: string }> => {
  let code = "-1";
  let message = "";
  try {
    const result = await instance.post(`/cus/knockOut/waive`, payload, true);
    code = result.data.code;
    message = result.data.message || "操作成功";
  } catch (e) {
    message = e.message;
  }
  return {
    code,
    message,
  };
};

export const removeKnockoutRings = async (
  instance: any,
  payload: { knockoutId: number | string },
): Promise<{ code: string; message: string }> => {
  let code = "-1";
  let message = "";
  try {
    const result = await instance.post(`/bus/game/removeKnockoutRings/${payload.knockoutId}`, {}, true);
    code = result.data.code;
    message = result.data.message || "操作成功";
  } catch (e) {
    message = e.message || "操作失败";
  }
  return {
    code,
    message,
  };
};

export const changeWin = async (
  instance: any,
  payload: { knockoutId: number | string },
): Promise<{ code: string; message: string }> => {
  let code = "-1";
  let message = "";
  try {
    const result = await instance.post(`/bus/game/changeWin/${payload.knockoutId}`, {}, true);
    code = result.data.code;
    message = result.data.message || "操作成功";
  } catch (e) {
    message = e.message || "操作失败";
  }
  return {
    code,
    message,
  };
};

export const deleteRoundRings = async (
  instance: any,
  payload: { knockoutId: number | string; roundNum: number },
): Promise<{ code: string; message: string }> => {
  let code = "-1";
  let message = "";
  try {
    const result = await instance.post(`/bus/game/removeSingleKnockoutRoundRings`, payload, true);
    code = result.data.code;
    message = result.data.message || "操作成功";
  } catch (e) {
    message = e.message || "操作失败";
  }
  return {
    code,
    message,
  };
};

export const targetSetting = async (
  instance: any,
  payload: Target,
): Promise<string> => {
  let resMessage = "";
  try {
    const result = await instance.post(`/bus/targetSetting/edit`, payload, true);
    const { code, message } = result.data;
    resMessage = message || code === "0" ? "操作成功" : "操作失败";
  } catch (e) {
    resMessage = e.message || "操作失败";
  }
  return resMessage;
};

export const querySetting = async (
  instance: any,
  payload: {
    gameGroupId: string;
  },
): Promise<Target> => {
  const result = await instance.get(`/bus/targetSetting/querySetting/${payload.gameGroupId}`, null, true);
  const data = result.data.data || {};
  return new Target(data);
};

export const queryKnockoutRoundSettings = async (
  instance: any,
  payload: {
    gameGroupId: string;
    finalRound: number;
  },
): Promise<Target> => {
  const result = await instance.post(`/bus/targetSetting/queryKnockoutRoundSettings`, payload, true);
  return result.data.data || [];
};

export const genQualifyingSite = async (
  instance: any,
  payload: {
    gameGroupId: string;
  },
): Promise<string> => {
  let resMessage = "";
  try {
    const result = await instance.post(`/bus/targetSetting/genQualifyingSite/${payload.gameGroupId}`, null, true);
    const { code, message } = result.data;
    resMessage = message || code === "0" ? "操作成功" : "操作失败";
  } catch (e) {
    resMessage = e.message || "操作失败";
  }
  return resMessage;
};

export const genKnockoutSite = async (
  instance: any,
  payload: {
    gameGroupId: string;
  },
): Promise<string> => {
  let resMessage = "";
  try {
    const result = await instance.post(`/bus/targetSetting/genKnockoutSite/${payload.gameGroupId}`, null, true);
    const { code, message } = result.data;
    resMessage = message || code === "0" ? "操作成功" : "操作失败";
  } catch (e) {
    resMessage = e.message || "操作失败";
  }
  return resMessage;
};

export const querySettingsByGameId = async (
  instance: any,
  payload: {
    matchId: string;
  },
): Promise<Array<Target>> => {
  const result = await instance.get(`/bus/targetSetting/querySettingsByGameId/${payload.matchId}`, null, true);
  const data = result.data.data || [];
  return data.map((item) => new Target(item));
};

export const queryUserListOnSameTarget = async (
  instance: any,
  payload: {
    groupId: string;
    userId: string;
  },
): Promise<Array<MatchRegistry>> => {
  const result = await instance.post(
    `/cus/gameSign/sameTargetNoUserList`,
    {
      gameGroupId: payload.groupId,
      userId: payload.userId,
    },
    true,
  );
  const data = result.data.data || [];
  return data.map((item) =>
    new MatchRegistry(item)).sort((a, b) => targetSort(a.qualifyingPosition, b.qualifyingPosition));
};

export const setMatchRegistration = async (
  instance: any,
  payload: {
    registrationId: string;
    userId: string;
    params: MatchRegistryParams;
  },
): Promise<void> => {
  await instance.post(
    `/bus/game/updateSignInfo`,
    {
      signId: payload.registrationId,
      userId: payload.userId,
      ...mapMatchRegistryParamsToServerParams(payload.params),
    },
    true,
  );
};

export const setMatchRegistryChecks = async (
  instance: any,
  payload: {
    registrationId: string;
    params: MatchRegistryCheckParams;
  },
): Promise<void> => {
  await instance.post(
    `/bus/sign/updateEquipmentCheck`,
    {
      signId: payload.registrationId,
      ...mapMatchRegistryCheckParamsToServerParams(payload.params),
    },
    true,
  );
};

export const downloadBlobData = (data: any, fileName: string): void => {
  const url = window.URL.createObjectURL(new Blob([ data ]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const exportQualifyingScore = async (
  instance: any,
  payload: {
    matchId: string;
    groupId: string;
  },
): Promise<void> => {
  const response = await instance.post(
    `/bus/excel/exportQualifyingScore/${payload.groupId}`,
    {},
    true,
    {
      responseType: "blob",
    },
  );
  downloadBlobData(
    response.data,
    `${payload.matchId}-${payload.groupId}-qualifying-${Date.now()}.xls`,
  );
};

export const exportKnockoutScore = async (
  instance: any,
  payload: {
    matchId: string;
    groupId: string;
  },
): Promise<void> => {
  const response = await instance.post(
    `/bus/excel/exportKnockoutScore/${payload.groupId}`,
    {},
    true,
    {
      responseType: "blob",
    },
  );
  downloadBlobData(
    response.data,
    `${payload.matchId}-${payload.groupId}-knockout-${Date.now()}.xls`,
  );
};

export const exportRegistrations = async (
  instance: any,
  payload: {
    matchId: string;
  },
): Promise<void> => {
  const response = await instance.post(
    `/bus/sign/export/${payload.matchId}`,
    {},
    true,
    {
      responseType: "blob",
    },
  );
  downloadBlobData(
    response.data,
    `${payload.matchId}-registrations-${Date.now()}.xls`,
  );
};

export const downloadIdPhoto = async (
  instance: any,
  payload: {
    matchId: string;
    fileName?: string;
  },
): Promise<void> => {
  const response = await instance.post(
    `/bus/game/downloadIdPhoto/${payload.matchId}`,
    {},
    true,
    {
      responseType: "blob",
    },
  );
  const fileName = payload.fileName || `${payload.matchId}-photo-${Date.now()}.zip`;
  downloadBlobData(
    response.data,
    `${fileName}.zip`,
  );
};

export const exportRankingEmptyScoreSheet = async (
  instance: any,
  payload: {
    groupId: string;
    roundId: string;
    contentOnly?: number; // 1-只返回内容无框线
    fileName?: string;
  },
): Promise<void> => {
  const response = await instance.post(
    `/bus/excel/exportQualifyingScorePaper`,
    {
      contentOnly: payload.contentOnly,
      gameGroupId: payload.groupId,
      round: Number(payload.roundId) + 1,
    },
    true,
    {
      responseType: "blob",
    },
  );
  const fileName = payload.fileName
   || `${payload.groupId}-ranking-${payload.roundId}-empty-score-sheet-${Date.now()}.xls`;
  downloadBlobData(
    response.data,
    `${fileName}.xls`,
  );
};

export const exportRankingEmptyScoreSheetPDF = async (
  instance: any,
  payload: {
    groupId: string;
    roundId: string;
    fileName?: string;
  },
): Promise<void> => {
  const response = await instance.post(
    `/bus/pdf/exportQualifyingScorePaper`,
    {
      gameGroupId: payload.groupId,
      round: Number(payload.roundId) + 1,
    },
    true,
    {
      responseType: "blob",
    },
  );
  const fileName = payload.fileName
  || `${payload.groupId}-ranking-${payload.roundId}-empty-score-sheet-pdf-${Date.now()}`;
  downloadBlobData(
    response.data,
    `${fileName}.pdf`,
  );
};

export const exportSingleRoundKnockoutEmptyScoreSheet = async (
  instance: any,
  payload: {
    seq?: number;
    contentOnly?: number; // 1-只返回内容无框线
    groupId: string;
    nthFinal: string;
    fileName?: string;
  },
): Promise<void> => {
  const response = await instance.post(
    `/bus/excel/exportKnockoutScorePaper`,
    {
      seq: payload.seq,
      gameGroupId: payload.groupId,
      finalRound: payload.nthFinal,
      contentOnly: payload.contentOnly,
    },
    true,
    {
      responseType: "blob",
    },
  );
  const fileName = payload.fileName
  || `${payload.groupId}-${payload.nthFinal}-knockout-empty-score-sheet-${Date.now()}`;
  downloadBlobData(
    response.data,
    `${fileName}.xls`,
  );
};

export const exportSingleRoundKnockoutEmptyScoreSheetPDF = async (
  instance: any,
  payload: {
    seq?: number;
    groupId: string;
    nthFinal: string;
    fileName?: string;
  },
): Promise<void> => {
  const response = await instance.post(
    `/bus/pdf/exportKnockoutScorePaper`,
    {
      seq: payload.seq,
      gameGroupId: payload.groupId,
      finalRound: payload.nthFinal,
    },
    true,
    {
      responseType: "blob",
    },
  );
  const fileName = payload.fileName
  || `${payload.groupId}-${payload.nthFinal}-knockout-empty-score-sheet-pdf-${Date.now()}`;
  downloadBlobData(
    response.data,
    `${fileName}.pdf`,
  );
};

export const getScorerList = async (
  instance: any,
  payload: {
    matchId: string;
  },
): Promise<Array<MatchScorer>> => {
  const result = await instance.get(`/bus/scoreUser/infoList/${payload.matchId}`, {}, true);
  const data = result.data.data || [];
  return data.map((item) => new MatchScorer(item));
};

export const addScorer = async (
  instance: any,
  payload: {
    matchId: string;
    userId: string;
  },
): Promise<void> => {
  await instance.post(`/bus/scoreUser/add`, {
    userId: payload.userId,
    gameId: payload.matchId,
  }, true);
};

export const removeScorer = async (
  instance: any,
  payload: {
    matchId: string;
    userId: string;
  },
): Promise<void> => {
  await instance.post(`/bus/scoreUser/delete`, {
    userId: payload.userId,
    gameId: payload.matchId,
  }, true);
};

export const checkIfCurrentUserIsScorer = async (
  instance: any,
  payload: {
    matchId: string;
  },
): Promise<boolean> => {
  const response = await instance.get(`/cus/user/checkScoreUser/${payload.matchId}`, { }, true);
  return Boolean(response.data.data);
};

export const getMatchNumberOfRegistriesAndFirstFiveRegistries = async (
  instance: any,
  payload: {
    matchId: string;
  },
): Promise<{ total: number; registries: Array<MatchRegistry> }> => {
  const result = await instance.post(`/cus/gameSign/signPage`, {
    gameId: payload.matchId,
    pageLimit: 5,
  }, true);
  const data = result.data.data;
  return {
    total: data?.total ?? 0,
    registries: _map(data?.list, (it) => new MatchRegistry(it)),
  };
};

export const generateSingleRoundKnockoutTarget = async (
  instance: any,
  payload: {
    seq?: number;
    groupId: string;
    nthFinal: string;
    targetLayout: TargetType;
    startTargetNumber: number;
  },
): Promise<void> => {
  const singleTargetFlag = payload.targetLayout === TargetType.SINGLE;
  await instance.post(`/bus/targetSetting/genRoundKnockoutSite`, {
    seq: payload.seq,
    gameGroupId: payload.groupId,
    finalRound: payload.nthFinal,
    singleTargetFlag,
    startTargetNo: payload.startTargetNumber,
  }, true);
};

export const setGroupRound = async (
  instance: any,
  payload: {
    groupId: string;
    targetStatus: GameGroupStatus;
    currentQualifyingRound?: number;
    qualifyingStatus?: number;
  },
): Promise<void> => {
  await instance.post("/bus/game/groupControl", {
    gameGroupId: payload.groupId,
    controlStatus: payload.targetStatus, // 轮次控制 0-未开始 1-排位开始，4-排位结束
    currentQualifyingRound: payload.currentQualifyingRound, // 当前排位赛轮次
    qualifyingStatus: payload.qualifyingStatus, // 当前轮次排位赛状态 0-未开始 1-进行中 2-已结束
  }, true);
};

export const createMatchGroup = async (
  instance: any,
  payload: {
    matchId: string;
    groupParams: MatchGroupParams;
  },
): Promise<void> => {
  await instance.post("/bus/game/addGroup", {
    gameId: payload.matchId,
    gameGroupList: [
      mapGroupParamsToServerParams(payload.groupParams),
    ],
  }, true);
};

export const updateMatchGroup = async (
  instance: any,
  payload: {
    groupId: string;
    groupParams: MatchGroupParams;
  },
): Promise<void> => {
  await instance.post("/bus/game/updateGroup", {
    gameGroupId: payload.groupId,
    ...mapGroupParamsToServerParams(payload.groupParams),
  }, true);
};

export const deleteMatchGroup = async (
  instance: any,
  payload: {
    groupId: string;
  },
): Promise<void> => {
  await instance.post(`/bus/game/deleteGroup/${payload.groupId}`, {}, true);
};

export const createMatch = async (
  instance: any,
  payload: {
    clubId: string;
    matchParams: MatchParams;
  },
): Promise<void> => {
  await instance.post("/bus/game/create", {
    clubId: payload.clubId,
    gameType: 4,
    ...mapMatchParamsToServerParams(payload.matchParams),
  }, true);
};

export const updateMatch = async (
  instance: any,
  payload: {
    matchId: string;
    matchParams: MatchParams;
  },
): Promise<void> => {
  await instance.post("/bus/game/update", {
    gameId: payload.matchId,
    ...mapMatchParamsToServerParams(payload.matchParams),
  }, true);
};

export const deleteMatch = async (
  instance: any,
  payload: {
    matchId: string;
  },
): Promise<void> => {
  await instance.post(`/bus/game/delete/${payload.matchId}`, { }, true);
};

export const verifyMatchRegistration = async (
  instance: any,
  payload: {
    registrationId: string;
  },
): Promise<void> => {
  await instance.post(`/bus/sign/verifyIdName/${payload.registrationId}`, null, true);
};

export const getMatchTotalNumberOfUniqueRegistries = async (
  instance: any,
  payload: {
    matchId: string;
  },
): Promise<{
  signUserNum: number; signNum: number;
}> => {
  const result = await instance.post(`/cus/gameSign/signUserNum/${payload.matchId}`, null, true);
  return result.data.data;
};

/**
 * 团体/混双报名
 */
export const teamSignUp = async (
  instance: any,
  payload: MatchTeamParams,
): Promise<void> => {
  await instance.post(`/bus/mixedGroup/sign`, {
    ...mapTeamParamsToServerParams(payload),
  }, true);
};

// 修改团体
export const updateMatchTeam = async (
  instance: any,
  payload: {
    mixedGroupId: string;
  } & MatchTeamParams,
): Promise<void> => {
  await instance.post(`/bus/mixedGroup/updateSign`, {
    ...mapTeamParamsToServerParams(payload),
    mixedGroupId: payload.mixedGroupId,
  }, true);
};

/**
 * 获取团体混双列表
 */
export const getMatchTeams = async (
  instance: any,
  payload: { matchId: string },
): Promise<Array<MatchTeam>> => {
  const result = await instance.post(`/bus/mixedGroup/list`, {
    gameId: payload.matchId,
  }, true);
  return _map(result.data.data, (it) => new MatchTeam(it));
};

/**
 * 删除团体
 */
export const deleteMatchTeam = async (
  instance: any,
  payload: { teamId: string },
): Promise<void> => {
  await instance.post(`/bus/mixedGroup/delete`, {
    mixedGroupIdList: [ payload.teamId ],
  }, true);
};

/**
 * 团体混双登记 小程序端
 */
export const mixedGroupRegisterRegister = async (
  instance: any,
  data: any,
): Promise<any> => {
  const result = await instance.post(`/cus/mixedGroupRegister/register`, data, true);
  return result;
};

/**
 * 随机报名比赛 仅测试用
 */
interface Result {
  code: string;
  message: string;
}
export const randomSign = async (
  instance: any,
  payload: {
    gameGroupId: number;
    num: number;
    sex?: number;
    clubId?: number;
  },
): Promise<Result> => {
  let result: Result = {
    code: "0",
    message: "操作成功",
  };
  try {
    await instance.post(`/bus/sign/randomSign`, payload, true);
  } catch (e) {
    result = {
      code: "-1",
      message: e.message || "操作失败",
    };
  }
  return result;
};

export const waive = async (
  instance: any,
  { signId }: {signId: string},
): Promise<void> => {
  await instance.post(`/cus/qualifyingScore/waive/${signId}`, {}, true);
};

export const cancelWaive = async (
  instance: any,
  { signId }: {signId: string},
): Promise<void> => {
  await instance.post(`/cus/qualifyingScore/cancelWaive/${signId}`, {}, true);
};

export const queryQualifyingExtraList = async (
  instance: any,
  { gameGroupId }: {gameGroupId: string},
): Promise<Record<string, Array<any>>> => {
  const result = await instance.post(`/bus/game/queryQualifyingExtraList/${gameGroupId}`, {}, true);
  const data = result.data.data || {};
  return data;
};

export interface QualifyingExtra {
  qualifyingRank: number;
  submitQualifyingExtraList: Array<{
    originRings: string;
    gameGroupId: number;
    groupNum: number;
    userId: number;
    extraRank: number;
  }>;
}
export const submitQualifyingExtra = async (
  instance: any,
  payload: QualifyingExtra,
): Promise<void> => {
  await instance.post(`/bus/game/submitQualifyingExtra`, payload, true);
};

export const updateKnockoutRank = async (
  instance: any,
  payload: {
    gameGroupId: string;
  },
): Promise<void> => {
  await instance.post(`/bus/game/updateKnockoutRank/${payload.gameGroupId}`, {}, true);
};

// 保存场地图
export const saveSchedule = async (
  instance: any,
  payload: {
    matchId: string;
    data: any;
    publish?: boolean;
  },
): Promise<void> => {
  await instance.post(`/bus/game/submitFieldMap`, {
    matchId: payload.matchId,
    version: payload.publish ? 1 : 0, // 0 = EDITING, 1 = publish
    fieldMapData: JSON.parse(payload.data ?? ""),
  }, true);
};

// 读取场地图
export const getSchedules = async (
  instance: any,
  payload: {
    matchId: string;
  },
): Promise<{
  remote?: string;
  published?: string;
}> => {
  const result = await instance.post(`/bus/game/queryFieldMap/${payload.matchId}`, {}, true);
  const list = result.data.data.fieldMapList;
  const remote = _findLast(list, (it) => it.version === "EDITING");
  const published = _findLast(list, (it) => it.version === "PUBLISHED");
  return {
    remote: remote == null ? undefined : JSON.stringify(remote),
    published: published == null ? undefined : JSON.stringify(published),
  };
};

export const signStaff = async (
  instance: any,
  payload,
): Promise<void> => {
  await instance.post(`/cus/gameSign/signStaff`, payload, true);
};

export const getSignStaffAll = async (
  instance: any,
  { matchId }: { matchId: string },
): Promise<Array<Staff>> => {
  const result = await instance.post(`/cus/gameSign/signStaffAll/${matchId}`, {}, true);
  const data = result.data.data;
  if (_isEmpty(data)) return [];
  return data.map((applicantRawData) => new Staff(applicantRawData));
};

export const getSignStaffInfo = async (
  instance: any,
  payload: { matchId: string; userId: string },
): Promise<Staff | null> => {
  try {
    const result = await instance.post(`/cus/gameSign/signStaffInfo`, {
      gameId: payload.matchId,
      userId: payload.userId,
    }, true);
    return new Staff(result?.data?.data || {});
  } catch (e) {
    return null;
  }
};

export const commonExport = async (
  instance: any,
  payload: {
    fileName: string;
    fileType?: string;
    path: string;
  },
): Promise<void> => {
  const response = await instance.post(
    payload.path,
    {},
    true,
    {
      responseType: "blob",
    },
  );
  downloadBlobData(
    response.data,
    `${payload.fileName}.${payload.fileType || "xlsx"}`,
  );
};

export const exportWinners = async (
  instance: any,
  payload: {
    fileName: string;
    matchId: string;
    admissionRank?: string;
  },
): Promise<void> => {
  const otherUrl = payload.admissionRank ? `?admissionRank=${payload.admissionRank}` : "";
  const response = await instance.post(
    `/bus/excel/exportWinners/${payload.matchId}${otherUrl}`,
    {},
    true,
    {
      responseType: "blob",
    },
  );
  downloadBlobData(
    response.data,
    `${payload.fileName}.xlsx`,
  );
};

export const exportLastFinalRoundTarget = async (
  instance: any,
  payload: {
    fileName: string;
    matchId: string;
    seq: string;
  },
): Promise<void> => {
  const response = await instance.post(
    `/bus/excel/exportLastFinalRoundTarget`,
    {
      gameId: payload.matchId,
      seq: payload.seq,
    },
    true,
    {
      responseType: "blob",
    },
  );
  downloadBlobData(
    response.data,
    `${payload.fileName}.xlsx`,
  );
};

// 导出比赛 PDF
export const exportPDF = async (
  instance: any,
  payload: {
    matchId: string;
  },
): Promise<void> => {
  await instance.post(
    `/bus/pdf/exportCoverAndCatalogue/${payload.matchId}`,
    {},
    true,
  );
};

// 获取导出 PDF 的状态
export const getPDFStatus = async (
  instance: any,
  payload: {
    matchId: string;
  },
): Promise<ExportStatus> => {
  try {
    const data = await instance.post(
      `/bus/pdf/getPdfStatus/${payload.matchId}`,
      {},
      true,
    );
    return data.data?.data || ExportStatus.UN_EXPORTED;
  } catch (error) {
    return ExportStatus.UN_EXPORTED;
  }
};

// 获取生成的 PDF 文件的 URL
export const getPdfFileUrl = async (
  instance: any,
  payload: {
    matchId: string;
  },
): Promise<string> => {
  const data = await instance.post(
    `/bus/pdf/getPdfFileUrl/${payload.matchId}`,
    {},
    true,
  );
  return data.data?.data || "";
};

export const exportKnockoutTarget = async (
  instance: any,
  payload: {
    fileName?: string;
    groupId: string;
    finalRound: string;
  },
): Promise<void> => {
  const fileName = payload.fileName || `淘汰赛靶位-${Date.now()}`;
  const response = await instance.post(
    `/bus/excel/exportKnockoutTarget`,
    {
      gameGroupId: payload.groupId,
      finalRound: payload.finalRound,
    },
    true,
    {
      responseType: "blob",
    },
  );
  downloadBlobData(
    response.data,
    `${fileName}.xlsx`,
  );
};

export const syncTarget = async (
  instance: any,
  payload: { matchId: string },
): Promise<void> => {
  await instance.post(`/bus/game/fieldMapDataToTargetSetting/${payload.matchId}`, {}, true);
};

export const genGroupKnockoutTarget = async (
  instance: any,
  payload: { gameGroupId: string },
): Promise<void> => {
  await instance.post(`/bus/targetSetting/genGroupKnockoutTarget/${payload.gameGroupId}`, {}, true);
};

export const exportQualifyingResultByRound = async (
  instance: any,
  payload: {
    filename: string;
    gameGroupId: string;
    roundNum: string;
  },
): Promise<void> => {
  const response = await instance.post(
    `/bus/excel/exportQualifyingResultByRound`,
    {
      gameGroupId: payload.gameGroupId,
      roundNum: payload.roundNum,
    },
    true,
    {
      responseType: "blob",
    },
  );
  downloadBlobData(
    response.data,
    `${payload.filename}.xlsx`,
  );
};

export const exportKnockoutCounterpartResultsByRound = async (
  instance: any,
  payload: {
    filename: string;
    gameGroupId: string;
    finalRound: string;
  },
): Promise<void> => {
  const response = await instance.post(
    `/bus/excel/exportKnockoutCounterpartResultsByRound`,
    {
      gameGroupId: payload.gameGroupId,
      finalRound: payload.finalRound,
    },
    true,
    {
      responseType: "blob",
    },
  );
  downloadBlobData(
    response.data,
    `${payload.filename}.xlsx`,
  );
};

// 非参赛人员删除
export const deleteStaff = async (
  instance: any,
  payload: {
    matchId: string;
    userId: string;
  },
): Promise<void> => {
  await instance.post(`/cus/gameSign/deleteStaff`, {
    userId: payload.userId,
    gameId: payload.matchId,
  }, true);
};

// 更新非参赛人员信息
export const updateStaff = async (
  instance: any,
  payload: Staff,
): Promise<void> => {
  await instance.post(`/cus/gameSign/updateSignStaff`, payload, true);
};

// 上传/编辑比赛文件
export const uploadOrEditMatchDocument = async (
  instance: any,
  payload: {
    documentId?: string;
    matchId?: string;
    fileName: string;
    fileUrl: string;
    delete?: boolean;
    type?: MatchDocumentType;
  },
): Promise<void> => {
  await instance.post(`/bus/gameFile/edit`, {
    id: payload.documentId,
    gameId: payload.matchId,
    fileName: payload.fileName,
    fileUrl: payload.fileUrl,
    delFlag: payload.delete === true ? 1 : 0,
    showType: payload.type ?? 0,
  }, true);
};

// 比赛文件列表
export const getMatchDocuments = async (
  instance: any,
  payload: {
    matchId?: string;
    fileName?: string;
    page: number;
    limit?: number;
    type?: MatchDocumentType;
  },
): Promise<{ current: number; total: number; list: Array<MatchDocument> }> => {
  const result = await instance.post(`/bus/gameFile/queryPage`, {
    gameId: payload.matchId,
    showType: payload.type ?? 0,
    fileName: payload.fileName,
    pageNo: payload.page,
    pageLimit: payload.limit ?? 10,
  }, true);
  const { current, total, list } = result.data.data;
  if (_isEmpty(list)) return { current, total, list: [] };
  const ret = {
    current,
    total,
    list: list.map((row) => new MatchDocument(row)),
  };
  return ret;
};

// 入场控制
export const getMatchAccessControlRules = async (
  instance: any,
  payload: {
    matchId: string;
  },
): Promise<Array<MatchAccessControlRule>> => {
  const result = await instance.post(`/bus/access/query`, {
    gameId: Number(payload.matchId),
  }, true);
  const data = result.data.data;
  if (_isEmpty(data)) return [];
  return data.map((it) => new MatchAccessControlRule(it));
};

export const saveMatchAccessControlRules = async (
  instance: any,
  payload: {
    matchId: string;
    rules: Array<MatchAccessControlRule>;
  },
): Promise<void> => {
  await instance.post(`/bus/access/edit`, {
    gameId: payload.matchId,
    ruleList: payload.rules.map((it) => it.serverParams),
  }, true);
};

export const checkMatchAccessControl = async (
  instance: any,
  payload: {
    matchId: string;
    userId: string;
    accessKeyOrArea: string;
  },
): Promise<boolean> => {
  const result = await instance.post(`/bus/access/checkin`, {
    gameId: payload.matchId,
    userId: payload.userId,
    area: payload.accessKeyOrArea,
  }, true);
  return Boolean(result?.data?.data);
};
